.ab-danger-stripes {
  margin-bottom: 15px;
  padding: 5px 10px;
  background: repeating-linear-gradient(
    -45deg,
    var(--ab-white),
    var(--ab-white) 10px,
    var(--ab-red-900) 10px,
    var(--ab-red-900) 20px
  );

  opacity: 0.5;

  @include media-breakpoint-up("lg") {
    margin-bottom: 0;
  }
}
