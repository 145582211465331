.hexagon-bkg {
  position: relative;
  z-index: 0;
  margin-bottom: 30px;
}

.hexagon-bkg__icon {
  $w: 550px;
  $h: 400px;
  position: absolute;
  @include airbrake-hexagon();
  width: $w;
  height: $h;
  top: -($h - 100px);
  left: 50%;
  margin-left: -($w * 0.5);
  z-index: -1;
  background-color: var(--ab-bkg-grey-inverted);
}
