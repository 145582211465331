.notice-title {
  display: block;
  min-width: 0; // From W3C specification: https://www.w3.org/TR/css-flexbox-1/#min-size-auto
  margin: 0 10px;
  font-size: $ab-font-size-x-default;
  font-weight: 300;
  letter-spacing: 0.03em;
  line-height: 1.3;
  color: var(--ab-grey-900);
  word-wrap: break-word;

  @include media-breakpoint-up("lg") {
    margin-left: 0;
  }
}

.notice-title__name {
  color: var(--ab-white);
}
