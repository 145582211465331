.ab-inline-tooltip {
  display: inline;
  vertical-align: text-top;
  padding-left: 3px;
  font-size: 13px;
}

.ab-inline-tooltip:hover {
  cursor: help;
}
