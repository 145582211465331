.dash-cta {
  text-align: center;
}

.dash-cta__image {
  display: flex;
  width: 200px;
  height: 170px;
  margin: 20px auto;
}
