.time-legend {
  position: relative;
  margin-right: 6px;
  padding-left: 18px;
}

.time-legend__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: opacity 300ms;
  border-radius: 5px;
  mix-blend-mode: multiply;
}

.time-legend__square {
  position: absolute;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
}

.time-legend__text {
  margin-bottom: 0;
  text-transform: lowercase;
}

.time-legend__percentage {
  margin-bottom: 0;
  font-size: $ab-font-size-default;
}

.time-legend__time {
  color: var(--ab-grey-600);
  font-size: $ab-font-size-x-small;
}
