.trends-dashboard {
  @include media-breakpoint-up("md") {
    display: flex;
    justify-content: center;
  }
}

.trends-dashboard__side {
  @include media-breakpoint-up("md") {
    flex-basis: 200px;
    margin-right: 40px;
  }
}

.trends-dashboard__main {
  text-align: center;

  @include media-breakpoint-up("md") {
    flex-grow: 1;
    max-width: 900px;
    text-align: left;
  }
}


.digest-stats {
  @include media-breakpoint-up("md") {
    display: flex;
  }
}

.digest-stats__errors-deploys,
.digest-stats__apm {
  @include media-breakpoint-up("md") {
    flex-basis: 50%;
  }
}

.digest-stats__errors-deploys {
  @include media-breakpoint-up("md") {
    margin-right: 20px;
  }
}
