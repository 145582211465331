.ab-chart {
  position: relative;
  display: block;
  margin: 0 10px 10px auto;
  padding-top: 15px;
  width: 100%;
  height: 200px;
  margin-right: 10px;
  z-index: 1;
}

.ab-chart--mini {
  margin: 0;
  width: 100px;
  height: 20px;
}

.ab-chart__chart {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
