.empty-state {
  position: relative;
  font-size: $ab-font-size-default;
  -webkit-font-smoothing: antialiased;
  color: var(--ab-text-dark);
}

.empty-state__box {
  overflow-y: auto;
  max-height: calc(100vh - 100px);
  max-width: 1000px;
  margin: 0 auto;
  padding: 15px 20px 40px 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  background-color: var(--ab-bkg-white-pure);
}

.empty-state__title {
  font-weight: 300;
  letter-spacing: 0.02em;
  color: var(--ab-text-dark);
}

.empty-state__bkg {
  position: absolute;
  top: 0;
  right: 0;

  @include media-breakpoint-up("lg") {
    top: 22%;
  }

  @include media-breakpoint-up("xl") {
    margin-right: 5%;
  }
}
