.info-box {
  margin-top: 20px;
  padding: 30px 35px;
  color: var(--ab-text-dark);
  background-color: var(--ab-bkg-blue-light);
  -webkit-font-smoothing: antialiased;
}

.info-box__title {
  margin-top: 0;
  font-size: $h3-font-size;
}

.info-box__text {
  margin-bottom: 9px;
}
