.card-slide {
  position: relative;
}

.card-slide__top,
.card-slide__bottom {
  transition: transform 1s;
}

.card-slide__bottom {
  position: absolute;
  bottom: 15px;
  top: 15px;
  left: 15px;
  right: 15px;
  transform: translateY( 110% );
}

.is-slid {
  .card-slide__top,
  .card-slide__bottom {
    transition: transform 0.5s;
  }

  .card-slide__top {
    transform: translateY(-90%);
  }

  .card-slide__bottom {
    transform: translateY(12%);
  }
}
