// Visually differentiate the feature-flagged / experimental parts of app for
// our own benefit, while we develop them.
.getexceptional {
  background: repeating-linear-gradient(
  -55deg,
  #f6f6f6,
  #f6f6f6 10px,
  #fff 10px,
  #fff 20px
  );
}
