.alerts-duration {
  height: 10px;
  background-color: var(--ab-green-500);
}

.alerts-duration__text {
  color: $body-color;
}

.alerts-metric {
  font-size: $font-size-base;
}

.alerts-metric__spike {
  font-size: $ab-font-size-default;
}

.alerts-metric__drop {
  font-size: $ab-font-size-x-small;
}

.alerts-table__pager {
  float: right;
}

.alerts__no-text-transform {
  text-transform: none;
}
