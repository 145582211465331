$search-font-size: 11px;

.ab-search {
  display: block;
  position: relative;
  padding: 3px 5px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  cursor: text;
  border-color: var(--ab-border-white);
  background-color: var(--ab-bkg-white-light);
}

.ab-search__icon {
  padding: 10px 0 0 10px;
  &:hover {
    text-decoration: none;
  }
}

.pill {
  position: relative;
  display: inline-block;
  margin: 3px 7px 3px 0;
  padding: 5px 7px;
  font-size: $search-font-size;
  cursor: default;
  transition: box-shadow 100ms linear;
  background-color: var(--ab-bkg-grey-pure);

  &:hover {
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
  }
}

.pill--inactive {
  cursor: pointer;
  background-color: var(--ab-bkg-grey-lighter);
  color: var(--ab-text-dark);
}

.pill--disabled {
  cursor: default;
  opacity: 0.5;

  &:hover {
    box-shadow: none;
  }
}

.pill--loading {
  opacity: 0.5;
  transition: opacity 100ms linear;
}

.pill__remove {
  margin-top: -3px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.pill__key {
  float: left;
  margin-right: 2px;
}

.pill__value {
  float: left;
  // Ideal max-width considering a narrow viewport (320px)
  // and the longest .pill__key at the time, "Environment"
  max-width: 187px;
  overflow: hidden;
  color: var(--ab-text-dark);

  input {
    border: none;
    color: var(--ab-text-dark);
    background: transparent;
    padding: 0;
  }
}

.ab-search__input {
  display: inline-block;
  width: auto;
  min-width: 50px;
  max-width: 100%;
  border: 0;
  margin: 0;
  padding: 8px 0 8px 2px;
  color: var(--ab-text-dark);

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.ab-search__language {
  border: 1px solid var(--ab-border-grey);
}

.ab-search__input--hidden {
  position: absolute;
}

.ab-search__suggestions {
  cursor: auto;
  display: block;
  // max-height to trigger transition
  max-height: 999px;
  opacity: 1;

  &.ng-hide-add, &.ng-hide-remove {
    transition: all 300ms;
  }

  &.ng-hide {
    max-height: 0;
    opacity: 0;
  }
}

.ab-search__suggestion-title {
  margin-right: 5px;
  color: var(--ab-grey-700);
  font-size: $search-font-size;
}

.ab-search__suggestion-upgrade-button {
  display: block;
  margin: 5px auto;
}

// We can't change these classes, so these are non-BEM selectors:
ngb-datepicker {
  position: absolute;
  width: 285px;

  .ngb-dp-header {
    background-color: var(--ab-bkg-white-pure);
  }

  .ngb-dp-month {
    flex-grow: 1;

    &:first-child .ngb-dp-week,
    &:last-child .ngb-dp-week {
      padding: 0;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--ab-bkg-white-pure);
    color: var(--ab-text-dark);
    border: none;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
    border-radius: 0;

    &:hover {
      backgroound-color: var(--ab-bkg-blue-light);
    }

    &.custom-select {
      height: 40px;
      padding-left: 17px;
      background-color: transparent;
    }
  }

  .ngb-dp-weekdays {
    background-color: transparent;
  }

  .ngb-dp-arrow {
    .btn {
      all: unset;
      padding: 0 10px;

      &:disabled {
        &:hover {
          backgroound-color: var(--ab-bkg-blue-light);
        }
      }
    }

    height: 40px;
    padding: 0 20px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      backgroound-color: var(--ab-bkg-blue-light);
    }
  }

  .ngb-dp-weekday {
    width: 40px;
    font-weight: 400;
    color: var(--ab-grey-700);
    font-style: normal;
  }

  .ngb-dp-day {
    width: auto;
    height: auto;
    flex: 1;
    border-radius: 1px;

    &.disabled {
      cursor: not-allowed;
    }

    &:hover:not(.disabled) {
      backgroound-color: var(--ab-bkg-blue-light);
    }
  }

  .ngb-dp-today {
    background-color: var(--ab-grey-1000);
    color: var(--ab-blue-700);
  }

  [ngbDatepickerDayView] {
    width: auto;
    height: auto;
  }
}

// We can't change these classes, so these are non-BEM selectors:
ngb-typeahead-window.dropdown-menu {
  max-height: 600px;
  overflow-y: auto;
  border: none;

  button.dropdown-item {
    display: block;
    width: 100%;
    text-align: left;
    padding: 5px 10px;
    font-size: $search-font-size;
    border: none;
    background-color: var(--ab-bkg-white-pure);

    &.active {
      background-color: var(--ab-bkg-orange);
      color: var(--ab-white);
    }

    // Text match highlight
    strong {
      font-weight: bold;
    }
  }
}
