.occurrence-header {
  @include media-breakpoint-up("xl") {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.occurrence-header__link,
.occurrence-header__title,
.occurrence-header__pager {
  margin-bottom: 10px;
  text-align: center;

  @include media-breakpoint-up("xl") {
    flex-grow: 1;
    margin-bottom: 0;
  }
}

.occurrence-header__link {
  @include media-breakpoint-up("xl") {
    text-align: left;
  }
}

.occurrence-link__icon {
  vertical-align: middle;
  text-decoration: none;
  padding-right: 5px;
}

.occurrence-header__title {
  font-size: 18px;
}

.occurrence-header__pager {
  @include media-breakpoint-up("xl") {
    text-align: right;
  }
}

.occurrence-header__text {
  margin-right: 10px;
}
