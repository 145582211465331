$breadcrumb-unit: 30px;

.ab-breadcrumb {
  display: flex;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom-width: 1px;
  border-bottom-style: dashed;
  border-bottom-color: var(--ab-grey-900);

  @include media-breakpoint-down("md") {
    flex-wrap: wrap;
  }
}

.ab-breadcrumb--sample {
  opacity: 0.5;

  &:before {
    content: '[sample] ';
  }
}

.ab-breadcrumb__item {
  margin-right: 15px;
  min-width: 0;
  flex-shrink: 0;
  line-height: $breadcrumb-unit;
  word-wrap: break-word;
}

.ab-breadcrumb__item--type {
  position: relative;
  flex-basis: $breadcrumb-unit;
  text-transform: uppercase;

  &:after {
    // visual vertical line
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 50%;
    width: 20px;
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--ab-grey-900);
  }
}

.ab-breadcrumb__item--date {
  flex-basis: 50px;
}

.ab-breadcrumb__item--severity {
  flex-basis: 40px;
}

.ab-breadcrumb__item--message {
  width: 100%;
  margin-top: 10px;

  @include media-breakpoint-up("sm") {
    width: auto;
    margin: 0;
    flex-shrink: 1;
  }
}

.ab-breadcrumb__item--duration {
  margin-right: 0;
  margin-left: auto;
}

.ab-breadcrumb-hexagon {
  position: relative;
  display: inline-block;
  z-index: 1;
  width: $breadcrumb-unit;
  height: $breadcrumb-unit;
  line-height: $breadcrumb-unit;
  text-align: center;
  @include airbrake-hexagon();
  color: var(--ab-white);
  font-size: 10px;
}

.ab-breadcrumb__upgrade-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:active {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
