.top-right-pagination {
  text-align: center;

  @include media-breakpoint-up("md") {
    position: absolute;
    top: 0;
    right: 20px;
    text-align: left;
  }
}
