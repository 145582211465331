.card-title-content {
  display: flex;
  padding-right: 30px;
  align-items: center;
}

.card-title-content__small {
  font-size: $ab-font-size-x-small;
  color: var(--ab-grey-400);
}

.card-title-content__no-shrink {
  flex-shrink: 0;
}

.card-title-content__count {
  margin: 0 5px;
  font-size: $ab-font-size-x-small;
  color: var(--ab-grey-600);
}

.card-title-content__icon {
  margin-right: 5px;
  font-size: $ab-font-size-small;
  transition: transform 100ms;

  &.is-opened {
    transform: rotate(45deg);
  }
}

.card-title-content__value {
  margin-left: 20px;
}
