.ab-bugs {
  position: relative;
  width: 280px;
  height: 320px;
  max-width: 100%;
  max-height: 100%;
}

.ab-bugs__bug-small {
  position: absolute;
  top: 0;
  right: 39%;
  width: 22%;
  transform: rotate(8deg);
}

.ab-bugs__bug-medium {
  position: absolute;
  top: 22%;
  right: 0;
  width: 32%;
  transform: rotate(-12deg);
}

.ab-bugs__bug-large {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 66%;
  transform: rotate(4deg);
}
