.card-with-shadow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  margin: 0 0 20px;
  padding: 15px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--ab-bkg-white-light);

  @include media-breakpoint-up("md") {
    width: 31.33%;
    margin: 0 1% 20px;
  }
}

.card-with-shadow--full-width {
  width: 100%;
  margin: 0 0 20px;
}

.card-with-shadow--orange {
  border-top: 4px solid var(--ab-border-orange);
}

.card-with-shadow--green {
  border-top: 4px solid var(--ab-border-green);
}

.card-with-shadow--blue {
  border-top: 4px solid var(--ab-border-blue);
}
