.ab-notice-view__header {
  display: flex;
  padding: 15px 8px 15px;
  align-items: center;
  background-color: var(--ab-notice-header);

  @include media-breakpoint-up("md") {
    padding: 15px 20px;
  }
}

.ab-notice-view__back {
  font-size: $ab-font-size-x-large;
  color: var(--ab-grey-900);

  &:hover {
    color: var(--ab-white);
    text-decoration: none;
  }
}

.ab-notice-view__button {
  margin-left: auto;
}

.ab-notice-view__body {
  position: relative;

  .nav-tabs {
    margin-bottom: 0;
    padding: 0 8px;
    border-bottom: none;
    background-color: var(--ab-notice-header);

    @include media-breakpoint-down("lg") {
      display: flex;
      overflow-x: auto;
      overflow-y: hidden;
    }

    > li {
      margin: 0;

      @include media-breakpoint-down("lg") {
        flex-shrink: 0;
      }

      a {
        margin: 0;
        padding: 12px;
        border: 0 !important;
        border-radius: 0;
        font-size: 12px;
        color: var(--ab-grey-800);

        &:hover {
          box-shadow: inset 0 -4px 0 var(--ab-white);
          background-color: transparent;
          color: var(--ab-white);
        }
      }
      &.active > a,
      &.active > a:hover,
      &.active > a:focus {
        box-shadow: inset 0 -4px 0 var(--ab-orange-400);
        background-color: var(--ab-blue-100);
        color: var(--ab-white);
      }

      .active {
        box-shadow: inset 0 -4px 0 var(--ab-orange-400);
        background-color: var(--ab-notice-header);
        color: var(--ab-white);
      }
    }
  }

  // ngbNavContent generated class
  .tab-content {
    position: relative;
    overflow-y: auto;
    top: -1px;

    @include media-breakpoint-up("md") {
      flex-grow: 1;
    }

    @include media-breakpoint-up("lg") {
      top: 0;
    }
  }

  // ngbNavContent generated class
  .tab-pane {
    height: inherit;

    @include media-breakpoint-up("md") {
      @include ab-absolute-to-edges();
      height: 100%;
    }
  }
}

.ab-notice-view__tab {
  border-radius: 0;
  height: inherit;
  padding: 12px;
  padding: $grid-gutter-width * 0.5;
  background-color: var(--ab-bkg-white-pure);
}
