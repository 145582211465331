.content-mask-container {
  display: block;
  position: relative;
  @include clearfix();
}

.content-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  text-align: center;
  background-color: var(--ab-bkg-white-transparent);
}

.content-mask__content {
  margin: 120px auto;
  padding: 2em;
  border-width: 1px;
  border-style: solid;
  border-color: var(--ab-border-grey);
  background-color: var(--ab-bkg-white-light);

  @include media-breakpoint-up("sm") {
    max-width: 70%;
  }
}

.content-mask__text {
  font-size: $ab-font-size-default;
}
