.languages-list {
  padding: 15px 0 0 15px;
  max-width: 1050px;
  margin: 0 35px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include media-breakpoint-up("xl") {
    max-width: 1200px;
  }
}


.languages-list__item {
  width: 120px;
  height: 120px;
  margin-bottom: 15px;
  border-radius: 2px;
  background-color: var(--ab-bkg-grey-light);

  @include media-breakpoint-up("md") {
    margin-right: 20px;
  }

  @include media-breakpoint-down("md") {
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--ab-bkg-grey-pure);
  }
}

.languages-list__img {
  margin-top: 25px;
  width: 40px;
  height: 40px;
}

.languages-list__text {
  margin-top: 15px;
  font-size: $ab-font-size-x-default;
  font-weight: 300;
  color: var(--ab-text-dark);
}
