.overlapped {
  position: relative;
  height: 315px;
}

.overlapped__img {
  position: absolute;
  right: 0;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.25);
}

.overlapped__img:last-child {
  left: 0;
  bottom: 0;
}

.overlapped--inverted {
  .overlapped__img {
    left: 0;
  }

  .overlapped__img:last-child {
    left: auto;
    right: 0;
  }
}
