.help-section {
  padding-top: 15px;
  font-size: $ab-font-size-large;
  font-style: italic;
}

.help-section__waiting-for-errors-text {
  font-style: normal;
  font-size: $ab-font-size-x-large;
}

.help-section__waiting-for-errors-dot {
  font-size: $ab-font-size-xxx-large;
  line-height: 0;
  animation-name: blink;
  animation-duration: 1.6s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  &:nth-child(2) {
    animation-delay: .3s;
  }

  &:nth-child(3) {
    animation-delay: .6s;
  }
}

@keyframes blink {
  0% {
    opacity: .1;
  }
  10% {
    opacity: 1;
  }
  100% {
    opacity: .1;
  }
}
