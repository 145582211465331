/* Generated by grunt-webfont */


@font-face {
	font-family:"ab-font";
	src:url("./ab-font-dc9a263083ee60aff4ca4f776982d6b0.woff") format("woff"),
		url("./ab-font-dc9a263083ee60aff4ca4f776982d6b0.ttf") format("truetype");
	font-weight:normal;
	font-style:normal;
}

.glyphicon {
	font-family:"ab-font";
	display:inline-block;
	vertical-align:middle;
	line-height:1;
	font-weight:normal;
	font-style:normal;
	speak:none;
	text-decoration:inherit;
	text-transform:none;
	text-rendering:optimizeLegibility;
	-webkit-font-smoothing:antialiased;
	-moz-osx-font-smoothing:grayscale;
}


/* Icons */


.glyphicon-ab-comment:before {
	content:"\f101";
}


.glyphicon-ab-gauge:before {
	content:"\f102";
}


.glyphicon-ab-integrations-assembla:before {
	content:"\f103";
}


.glyphicon-ab-integrations-bitbucket:before {
	content:"\f104";
}


.glyphicon-ab-integrations-github:before {
	content:"\f105";
}


.glyphicon-ab-integrations-gitlab:before {
	content:"\f106";
}


.glyphicon-ab-integrations-jira:before {
	content:"\f107";
}


.glyphicon-ab-integrations-lighthouse:before {
	content:"\f108";
}


.glyphicon-ab-integrations-pivotal:before {
	content:"\f109";
}


.glyphicon-ab-integrations-trello:before {
	content:"\f10a";
}


.glyphicon-ab-layers:before {
	content:"\f10b";
}


.glyphicon-ab-logo:before {
	content:"\f10c";
}


.glyphicon-ab-menu-hamburger:before {
	content:"\f10d";
}


.glyphicon-ab-pin-remove:before {
	content:"\f10e";
}


.glyphicon-ab-pin:before {
	content:"\f10f";
}


.glyphicon-ab-plus:before {
	content:"\f110";
}


.glyphicon-ab-search:before {
	content:"\f111";
}


.glyphicon-angle-double-left:before {
	content:"\f112";
}


.glyphicon-angle-double-right:before {
	content:"\f113";
}


.glyphicon-angle-left:before {
	content:"\f114";
}


.glyphicon-angle-right:before {
	content:"\f115";
}


.glyphicon-arrow-down:before {
	content:"\f116";
}


.glyphicon-arrow-right:before {
	content:"\f117";
}


.glyphicon-arrow-up:before {
	content:"\f118";
}


.glyphicon-bar-chart:before {
	content:"\f119";
}


.glyphicon-bell-o:before {
	content:"\f11a";
}


.glyphicon-bell-slash-o:before {
	content:"\f11b";
}


.glyphicon-bug:before {
	content:"\f11c";
}


.glyphicon-caret-down:before {
	content:"\f11d";
}


.glyphicon-caret-up:before {
	content:"\f11e";
}


.glyphicon-check-circle:before {
	content:"\f11f";
}


.glyphicon-check-square-o:before {
	content:"\f120";
}


.glyphicon-check:before {
	content:"\f121";
}


.glyphicon-chevron-down:before {
	content:"\f122";
}


.glyphicon-chevron-left:before {
	content:"\f123";
}


.glyphicon-chevron-right:before {
	content:"\f124";
}


.glyphicon-chevron-up:before {
	content:"\f125";
}


.glyphicon-clock-o:before {
	content:"\f126";
}


.glyphicon-cog:before {
	content:"\f127";
}


.glyphicon-credit-card:before {
	content:"\f128";
}


.glyphicon-download:before {
	content:"\f129";
}


.glyphicon-envelope:before {
	content:"\f12a";
}


.glyphicon-exclamation-triangle:before {
	content:"\f12b";
}


.glyphicon-external-link:before {
	content:"\f12c";
}


.glyphicon-facebook-square:before {
	content:"\f12d";
}


.glyphicon-flash:before {
	content:"\f12e";
}


.glyphicon-group:before {
	content:"\f12f";
}


.glyphicon-key:before {
	content:"\f130";
}


.glyphicon-laptop:before {
	content:"\f131";
}


.glyphicon-line-chart:before {
	content:"\f132";
}


.glyphicon-lock:before {
	content:"\f133";
}


.glyphicon-map-marker:before {
	content:"\f134";
}


.glyphicon-markdown-mark-solid:before {
	content:"\f135";
}


.glyphicon-phone:before {
	content:"\f136";
}


.glyphicon-print:before {
	content:"\f137";
}


.glyphicon-question-circle:before {
	content:"\f138";
}


.glyphicon-refresh:before {
	content:"\f139";
}


.glyphicon-share-alt:before {
	content:"\f13a";
}


.glyphicon-sort-amount-desc:before {
	content:"\f13b";
}


.glyphicon-square-o:before {
	content:"\f13c";
}


.glyphicon-times:before {
	content:"\f13d";
}


.glyphicon-twitter-square:before {
	content:"\f13e";
}


.glyphicon-user:before {
	content:"\f13f";
}
