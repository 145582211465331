.refresh-bar {
  padding: 8px 15px 8px 10px;
  line-height: 26px;
  color: var(--ab-text-dark);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  opacity: 1;
  border-color: var(--ab-border-red);
  background-color: var(--ab-bkg-orange-light);
}

.refresh-bar.ng-hide-remove {
  transition: all linear 0.2s;
}

.refresh-bar.ng-hide {
  opacity: 0;
}

.refresh-bar__counter {
  font-weight: 700;
}

.refresh-bar__btn {
  float: right;
}

.refresh-bar__icon {
  vertical-align: baseline;
}
