$margin: 5px;

.ab-cards {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: -$margin;
}

.ab-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-width: 1px;
  border-style: solid;
  margin: $margin;
  padding: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 45%;
  line-height: 1;
  border-color: var(--ab-border-grey);
  color: var(--ab-text-light);
  background-color: var(--ab-bkg-white-light);

  @include media-breakpoint-up("md") {
    flex-basis: 0;
    padding: 15px;
    min-width: 125px;
  }
}

.ab-card--white {
  border-color: var(--ab-border-white);
}

.ab-card--wide {
  min-width: 180px;

  @include media-breakpoint-up("md") {
    min-width: 225px;
  }
}

.ab-card--no-border {
  margin-bottom: 15px;
  padding: 0;
  border: none;
  align-items: baseline;
  flex-direction: row;
  justify-content: flex-start;
  font-size: $ab-font-size-x-default;
  line-height: $h3-font-size;
  -webkit-font-smoothing: antialiased;
  color: var(--ab-grey-600);

  @include media-breakpoint-up("lg") {
    min-width: 100%;
  }

  @include media-breakpoint-up("xl") {
    min-width: auto;
  }
}

.ab-card__data {
  margin: 5px 0 0;
  font-size: $h3-font-size;
  color: var(--ab-text-dark);

  @include media-breakpoint-up("md") {
    margin: 0;
    font-size: $h1-font-size;
    line-height: 1;
  }

  .ab-card--no-border & {
    position: relative;
    top: 2px;
    margin-right: 10px;
    flex-shrink: 0;
    font-weight: 300;
    color: var(--ab-text-dark);
  }
}

.ab-card__label {
  position: relative;
}

.ab-card__title {
  color: var(--ab-black);
}

.ab-card__data--medium {
  margin: 5px 0 0;

  @include media-breakpoint-up("md") {
    font-size: $h2-font-size;
  }
}

.ab-card__data--small {
  color: var(--ab-grey-700);

  @include media-breakpoint-up("md") {
    font-size: $h3-font-size;
  }
}

.ab-card__data--orange {
  color: var(--ab-orange-400);
}

.ab-card__trend {
  position: absolute;
  right: 10px;
  bottom: 12px;
  font-size: $ab-font-size-x-small;

  @include media-breakpoint-up("md") {
    bottom: 17px;
  }

  .ab-card--no-border & {
    min-width: 100px;
    left: 0;
    bottom: -15px;
  }
}

.ab-card__alert {
  position: absolute;
  top: 0;
  right: 0;
  color: var(--ab-white);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-top-width: 12px;
    border-top-style: solid;
    border-top-color: var(--ab-red-400);
    border-bottom: 12px solid transparent;
    border-left: 12px solid transparent;
    border-right-width: 12px;
    border-right-style: solid;
    border-right-color: var(--ab-red-400);
  }

  &:after {
    content: "!";
    position: absolute;
    top: 2px;
    right: 5px;
  }
}
