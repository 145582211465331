.language-logo {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

$languages: (
  "android",
  "angular",
  "angularjs",
  "django",
  "express",
  "flask",
  "go",
  "ios",
  "java",
  "javascript",
  "laravel",
  "magento",
  "net",
  "node",
  "other",
  "php",
  "python",
  "rack",
  "rails",
  "react",
  "react-native",
  "ruby",
  "sinatra",
  "swift",
  "svelte",
  "vuejs",
  "wordpress",
  "aiohttp",
  "bottlepy",
  "celery",
  "cherrypy",
  "fastapi",
  "falcon",
  "hug",
  "morepath",
  "masonite",
  "pycnic",
  "pyramid",
  "sanic",
  "starlette",
  "tornado",
  "turbogears2",
  "beego",
  "buffalo",
  "echo",
  "fasthttp",
  "fiber",
  "gin",
  "gorilla",
  "iris",
  "negroni",
  "nethttp",
);

@each $language in $languages {
  .language-logo--#{$language} {
    background-image: url('../../assets/images/languages/#{$language}.svg');
  }
}

// Dark mode specific cases
@mixin dark-languages {
  .dark { @content; }
  @media (prefers-color-scheme: dark) { @content }
}

.dark{
  .language-logo--fiber {
    background-image: url('../../assets/images/languages/fiber-invert.svg');
  }

}

.light {
  .language-logo--starlette {
    background-image: url('../../assets/images/languages/starlette-invert.svg');
  }
  .language-logo--flask {
    background-image: url('../../assets/images/languages/flask.svg');
  }
}

@include dark-languages {
  .language-logo--express {
    background-image: url('../../assets/images/languages/express-invert.svg');
  }

  .language-logo--flask {
    background-image: url('../../assets/images/languages/flask-invert.svg');
  }
}
