$navbar-border-radius: 0;

.navbar {
  padding: 0;
  margin-bottom: 0;
  min-height: 50px;
  border: none;
  transition: background 500ms ease-in;
  background-color: var(--ab-grey-200);

  .dropdown-menu {
    background-color: var(--ab-grey-200);
  }
}

.navbar--blue{
  background-color: var(--ab-blue-100);
}

.ab-navbar-brand {
  display: block;
  width: 35px;
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.ab-navbar-brand__logo {
  font-size: 35px;
  color: var(--ab-white);
}

.user-dropdown {
  max-width: 100%;
  position: relative;

  .user-name {
    + .dropdown-menu {
      left: auto;
      right: 0;

      @include media-breakpoint-down("md") {
        top: 40px;
      }
    }
  }

  .dropdown-toggle {
    display: flex;
    align-items: center;
    max-width: 100%;
    color: var(--ab-grey-600);

    + .dropdown-menu {
      li {
        a {
          color: var(--ab-grey-600);
          .glyphicon {
            min-width: 22px;
          }
          &.highlight {
            color: var(--ab-orange-400);
          }
        }
        &:hover > a,
        &:hover > ab-external-link > a {
          color: var(--ab-white);
        }
      }
    }

    &:hover,
    &[aria-expanded="true"] {
      text-decoration: none;
      color: var(--ab-white);
    }
  }

  .dropdown-menu {
    a:hover {
      background: inherit;
    }
    hr {
      border-color: var(--ab-border-grey-neutral);
      margin: 5px 0 0 0;
    }
  }
}

.user-dropdown__avatar {
  display: inline-block;
  position: relative;
}

.checklist-indicator {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 9px;
  height: 9px;
  border-width: 2px;
  border-style: solid;
  border-color: var(--ab-border-grey-neutral);
  border-radius: 100%;
  background-color: var(--ab-orange-400);
}

.navbar__divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: var(--ab-border-grey-neutral);

  @include media-breakpoint-up("md") {
    display: inline-block;
    float: left;
    margin-top: 15px;
    margin-left: 8px;
    height: 20px;
    border-left-width: 1px;
    border-left-style: solid;
  }
}

.navbar-left-menu {
  position: absolute;
  left: -80%;
  width: 80%;
  flex-grow: 1;
  background-color: var(--ab-grey-100);

  @include media-breakpoint-up("md") {
    display: flex;
    position: static;
    width: auto;
    flex: 0 0 auto;
    background-color: transparent;
  }
}

.project-nav {
  flex-shrink: 0;
}

.navbar-menu-toggle {
  display: inline-block;
  padding: 1em 0;
  color: var(--ab-grey-600);

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    color: var(--ab-white);
    outline: 0;
  }
}
