.loading-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: not-allowed;
  background-color: rgba(255, 255, 255, 0.45);
}

.pinned-item {
  position: absolute;
  right: 5px;

  &:hover {
    font-size: 1.1em;
    color: var(--ab-red-400);
  }
}

.attr-list-subtitle {
  display: flex;
  margin: 1em 0;
  cursor: default;
}

.attr-list-intro {
  margin-top: 10px;
  color: var(--ab-grey-600);
}

.attr-advanced {
  position: relative;
}

.attr-advanced__upgrade-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:active {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
