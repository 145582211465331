.ab-shortcuts-list {
  margin-bottom: 0;

  dt {
    float: left;
    width: 100px;
  }

  dd {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
