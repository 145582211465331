.project-instructions {
  font-size: 16px;
  position: relative;

  // Skip BEM naming for this module because the markup is generated via
  // markdown files and it's not possible to define CSS classes
  h3 {
    margin-top: 36px;
    margin-bottom: 24px;
  }

  ul, ol {
    padding-left: 1em;
  }

  p, pre {
    margin-bottom: 1em;
    line-height: 1.5;
  }

  code {
    display: inline-block;
    color: var(--ab-text-dark);
    background-color: var(--ab-bkg-grey-inverted);
  }

  pre {
    font-size: 14px;
  }
}

.project-instructions__copy-btn {
  &,
  &:focus,
  &:active {
    position: absolute;
    right: 0px;
    margin: 2px;
    top: unset;
  }
}
