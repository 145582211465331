// Common styles for both the group header, and the groups list controls.
.groups-multi-select {
  display: flex;
  padding: 6px 10px;
  align-items: center;;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--ab-border-grey);
  background-color: var(--ab-bkg-grey-lighter);
}

.groups-multi-select__checkbox {
  margin-right: 4px;
}

.groups-multi-select__paginate {
  margin-left: auto;
}

.groups-multi-select__counter {
  display: inline-block;
  margin: 0 10px 0 0;
  color: var(--ab-grey-600);
}

.groups-multi-select__refresh {
  margin-right: 5px;
  color: inherit;

  &:hover {
    font-size: 1.2em;
    text-decoration: none;
  }
}
