.tolerating .value {
  color: var(--ab-orange-400);
}

.frustrated .value {
  color: var(--ab-red-400);
}

.route-counter {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: $font-size-base;

  @include media-breakpoint-down("md") {
    margin-bottom: 30px;
  }
}
