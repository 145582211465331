.ab-trend {
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up("md") {
    display: flex;
    flex-wrap: wrap;
  }
}

.ab-trend__title {
  flex-basis: 100%;
}

.ab-trend__trend {
  flex-basis: 30%;
  font-size: $ab-font-size-xx-large;
  line-height: 1;
}

.ab-trend__number {
  margin: 0;
  color: var(--ab-text-dark);
  font-size: $ab-font-size-large;
  line-height: 1;
}

.ab-trend__percentage {
  font-weight: bold;
  font-size: $ab-font-size-default;
}

.ab-trend__small {
  margin: 0;
  color: var(--ab-grey-600);
  font-size: $ab-font-size-small;
}
