.ab-deploy-pagination {
  position: relative;
  display: flex;
  margin: 40px auto 20px;
  max-width: 800px;
  justify-content: space-between;
  text-align: center;
}

.ab-deploy-pagination__section {
  position: relative;
  flex-grow: 1;
  flex-basis: 33%;
}

.ab-deploy-pagination__current {
  margin-top: 4px;
  margin-bottom: 20px;
}

.ab-deploy-pagination__circle {
  position: absolute;
  top: 45px;
  left: 48%;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--ab-blue-400);
}

.ab-deploy-pagination__line {
  position: absolute;
  top: 50px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--ab-border-blue);
}

.ab-deploy-pagination__line--in-right {
  right: 0;
  left: 53%;
}

.ab-deploy-pagination__line--in-left {
  right: 55%;
  left: 0;

  // Triangle tip
  &:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -3px;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left-width: 8px;
    border-left-style: solid;
    border-left-color: var(--ab-blue-400);
  }
}

.ab-deploy-pagination__btn {
  padding-top: 30px;
}

.ab-arrow-right {
  font-size: 8px;
  vertical-align: middle;
  margin: 0 2px;
}

.blob {
  width: 100%;
}

.blob-code {
  white-space: pre;
  font-family: $font-family-monospace;
}

.blob-code--addition {
  color: var(--ab-green-500);
  background-color: var(--ab-bkg-green-light);
}

.blob-code--deletion {
  color: var(--ab-red-500);
  background-color: var(--ab-bkg-red);
}

.blob-code--summary {
  color: var(--ab-blue-500);
  background-color: var(--ab-bkg-blue-light);
}

.blob-code--header {
  padding: 10px 0;
  font-weight: 600;
}
