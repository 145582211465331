.groups-panel {
  border: none;
  margin-bottom: 0;

  @include media-breakpoint-up("md") {
    @include ab-absolute-to-edges();
  }
}

.no-group-selected-container {
  background-color: var(--ab-bkg-grey-light);
}
.no-group-selected {
  overflow: hidden !important;
  position: relative;

  > div {
    width: 85%;
    text-align: center;
    margin: 0 auto;

    .glyphicon-ab-logo {
      color: #e6e6e6;
      font-size: 300px;
    }

    h3 {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}

.groups {
  // Eliminate normalized `li` left padding
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .group {
    &.group-resolved {
      .badge {
        background-color: var(--ab-grey-700);;
      }
    }
  }

  .group-selector {
    margin: 9px 5px 0 10px;
  }
}

.group-block {
  display: block;
  padding: 10px;
  box-shadow: inset 0 -1px 0 0 var(--ab-border-grey);
  transition: all linear 0.15s;
  color: var(--ab-text-dark);

  &:hover,
  &:focus,
  .group:focus & {
    cursor: pointer;
    text-decoration: none;
    outline: none;
    background-color: var(--ab-bkg-grey-inverted);
  }

  .group-active & {
    box-shadow:
      inset 0 -1px 0 0 var(--ab-group-active-shadow-1),
      inset -4px 0 0 0 var(--ab-group-active-shadow-2);
    color: var(--ab-group-active);
    background-color: var(--ab-bkg-grey-pure);
  }

  .group-new & {
    box-shadow:
      inset 0 -1px 0 0 var(--ab-grey-800),
      inset -4px 0 0 0 var(--ab-orange-400);
  }

  .minor-text {
    font-size: 10px;
    line-height: 16px;
    color: var(--ab-grey-600);
  }

  .group-body {
    clear: both;
    padding: 9px 0 0 30px;
    max-height: 88px;
    overflow: hidden;
    font-size: 12px;
    line-height: 16px;

    .ab-type {
      font-weight: 500;
    }
  }
}

.no-results {
  padding: 45px 15px 15px;
  text-align: center;
  overflow: hidden !important;
  position: relative;
}

.no-results__text {
  margin-bottom: 45px;
  color: var(--ab-text-light);
}

.all-resolved {
  color: var(--ab-grey-500);
  margin: 50px 40px;
  text-align: center;
}

.all-resolved__icon {
  margin: auto;
  height: 200px;
  width: 200px;
  background-image: url('../../assets/images/resolved.svg');
  background-position: center;
  background-repeat: no-repeat;
}

.sparkline-barchart {
  margin-left: 15px;
  width: 100px;
  height: 20px;
  line-height: 30px;
}

.new-notice-count {
  color: var(--ab-grey-100);
  background-color: var(--ab-orange-900);
}
