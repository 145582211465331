.occurrence-graph__controls {
  display: flex;
  margin-bottom: 40px;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-up("lg") {
    margin-bottom: 5px;
    justify-content: flex-end;
  }
}

.occurrence-graph__legend {
  font-size: $ab-font-size-x-small;

  @include media-breakpoint-up("lg") {
    margin-right: 10px;
  }
}

.occurrence-graph__barchart {
  height: 140px;
}
