.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: var(--ab-white);
  border-radius: 3px;
  transition: all .1s ease;
  pointer-events: none;
  transform: translate(-50%, 0);
  z-index: 1;
}

.chartjs-tooltip-title {
  padding-bottom: 3px;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  border-width: 0.5px;
  border-style: solid;
  border-color: var(--ab-white);
}
