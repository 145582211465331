.ng-select .ng-select-container,
.ng-select.ng-select-opened > .ng-select-container,
.ng-dropdown-panel {
  color: var(--ab-text-dark);
  border-color: var(--ab-border-grey);
  background-color: var(--ab-bkg-grey-light);
}

.ng-select.ng-select-disabled > .ng-select-container {
  background-color: var(--ab-bkg-grey-light);
  opacity: 0.5;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  color: var(--ab-text-default);
  background-color: var(--ab-bkg-white-light);
}

.ng-dropdown-panel.ng-select-top,
.ng-dropdown-panel.ng-select-bottom {
  border-top-color: var(--ab-border-grey);
  border-bottom-color: var(--ab-border-grey);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  color: var(--ab-text-light);
  background-color: var(--ab-bkg-grey-light);
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel
  .ng-dropdown-panel-items
  .ng-option.ng-option-selected.ng-option-marked {
  color: var(--ab-text-default);
  background-color: var(--ab-bkg-grey-lighter);
}

.ng-select .ng-select-container .ng-value-container .ng-input > input {
  color: var(--ab-text-dark);
}
