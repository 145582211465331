.integration-logo {
  margin-bottom: 20px;
  width: 130px;
  height: 30px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @include media-breakpoint-up("sm") {
    margin-right: 20px;
    margin-bottom: 0;
  }

  @include media-breakpoint-up("md") {
    margin-right: 0;
    margin-bottom: 20px;
  }

  @include media-breakpoint-up("lg") {
    margin-right: 20px;
    margin-bottom: 0;
  }
}

@mixin integration-logo-bkg($class, $bkg) {
  .integration-logo--#{$class} {
    background-image: url($bkg + '.svg');

    // Dark mode
    .dark & { background-image: url($bkg + '-light.svg'); }
    @media (prefers-color-scheme: dark) {
      .system_default & { background-image: url($bkg + '-light.svg'); }
    }
  }
}

@include integration-logo-bkg('slack', '../../assets/images/slack');
@include integration-logo-bkg('github', '../../assets/images/github');
@include integration-logo-bkg('bitbucket', '../../assets/images/bitbucket');
@include integration-logo-bkg('trello', '../../assets/images/trello');
@include integration-logo-bkg('webhooks', '../../assets/images/webhooks');
@include integration-logo-bkg('jira', '../../assets/images/jira');
