.kv-table {
  width: 100%;
  table-layout: fixed;
}

.kv-table--lg-flex {
  @include media-breakpoint-up("xl") {
    display: flex;
    align-items: center;
  }
}

.kv-table__title {
  margin-top: 1.5em;
}

.kv-table__url {
  display: block;
}

.kv-table__key,
.kv-table__value {
  padding: 10px;
  padding-left: 0;
  word-wrap: break-word;
  line-height: 20px;
}

.kv-table__key {
  text-transform: uppercase;
  color: var(--ab-grey-600);
  width: 140px;
}

.kv-table__kv--no-top-padding {
  padding-top: 0;
}

.kv-table__value {
  color: var(--ab-text-dark);
}

.kv-table__cta {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  line-height: 1.4;
  background-color: var(--ab-bkg-blue-light);

  @include media-breakpoint-up("xl") {
    margin-left: 10px;
  }

  &:before {
    $size: 20px;
    content: "";
    position: absolute;
    top: -$size;
    left: 50%;
    margin-left: -$size*0.5;
    width: $size;
    height: $size;
    border-top: $size*0.5 solid transparent;
    border-right: $size*0.5 solid transparent;
    border-left: $size*0.5 solid transparent;
    border-bottom-width: $size*0.5;
    border-bottom-style: solid;
    border-bottom-color: var(--ab-bkg-blue-light);

    @include media-breakpoint-up("xl") {
      position: static;
      margin-left: -$size*1.5;
      margin-right: $size*0.5;
      border-right-width: $size*0.5;
      border-right-style: solid;
      border-right-color: var(--ab-bkg-blue-light);
      border-bottom: $size*0.5 solid transparent;
    }
  }
}

.kv-table__no-shrink {
  flex-shrink: 0;
}
