.spinner {
  margin-top: 30px;
  color: var(--ab-grey-600);
  text-align: center;
}

.spinner__svg {
  vertical-align: middle;
}

.spinner__icon {
  stroke-dasharray: 90;
  stroke-dashoffset: 180;
  animation: drawdash 0.4s linear forwards;
  animation-iteration-count: infinite;
}

.spinner--inline {
  display: inline;
  margin: 0 5px 0 0;

  .spinner__icon {
    stroke-dasharray: 24;
    stroke-dashoffset: 48;
  }
}

@keyframes drawdash {
  to {
    stroke-dashoffset: 0;
  }
}

.spinner__label {
  font-size: 13px;
  line-height: 54px;
  -webkit-font-smoothing: antialiased;

  &:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }

  40% {
    color: var(--ab-grey-600);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }

  60% {
    text-shadow:
      .25em 0 0 var(--ab-grey-600),
      .5em 0 0 rgba(0,0,0,0);
  }

  80%, 100% {
    text-shadow:
      .25em 0 0 var(--ab-grey-600),
      .5em 0 0 var(--ab-grey-600);
  }
}
