.page-header {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.5em ($grid-gutter-width * 0.5);
  border-bottom: none;
  text-align: center;
  color: var(--ab-white);
  background-color: var(--ab-grey-200);

  @include media-breakpoint-up("md") {
    flex-direction: row;
    align-items: center;
  }
}

.page-header--blue {
  background-color: var(--ab-blue-100);
}

.page-header__title {
  margin: 0;
  margin-bottom: 15px;
  padding: 2px 0;
  font-size: $h2-font-size;
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up("md") {
    float: left;
    margin-right: 10px;
    margin-bottom: 0;
  }
}

.page-header__element {
  margin-top: 5px;

  @include media-breakpoint-up("md") {
    flex-shrink: 0;
    margin-top: 0;
    margin-left: 5px;
  }
}

.page-header__on-right {
  margin-top: 15px;

  @include media-breakpoint-up("md") {
    margin-top: 0;
    margin-left: auto;
  }
}

.page-header__back {
  margin-bottom: 10px;
  white-space: nowrap;
  color: var(--ab-white);
  -webkit-font-smoothing: antialiased;

  &:hover {
    color: var(--ab-white);
  }

  @include media-breakpoint-up("md") {
    margin-bottom: 0;
    margin-right: 15px;
  }
}
