.time-bar {
  display: flex;
  flex-grow: 1;
  height: 20px;
  align-self: flex-end;

  @include media-breakpoint-up("md") {
    margin-left: 20px;
  }
}

.time-bar__item {
  z-index: 1;
  height: 20px;
  position: relative;
}

.time-bar__item__arrow {
  opacity: 0;
  position: absolute;
  top: -10px;
  left: -2px;
  position: absolute;
  width: 0;
  height: 0;
  border-top-width: 8px;
  border-top-style: solid;
  border-color: var(--ab-border-grey-neutral);
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  transition: opacity 300ms;
}
