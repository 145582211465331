.attr-preview__tip {
  position: relative;
  top: 2px;
  margin-left: 5px;
  line-height: 32px;
  color: var(--ab-grey-600);
}

.attr-preview__btn {
  margin-right: 10px;
  margin-bottom: 10px;
}
