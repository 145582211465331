.empty-state-header {
  display: flex;
  padding: $grid-gutter-width * 0.5;
  margin-top: 0;
  color: var(--ab-white);
  justify-content: center;
  z-index: 1;
}

.empty-state-header__content {
  max-width: 780px;
}

.empty-state-header__img {
  width: 320px;
}
