.diffstats {
  font-size: 12px;
  font-weight: 600;
  color: var(--ab-grey-500);
}

.diffstats__block {
  margin-left: 1px;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.diffstats__block--added {
  background-color: var(--ab-green-500);
}

.diffstats__block--deleted {
  background-color: var(--ab-red-300);
}

.diffstats__block--neutral {
  background-color: var(--ab-grey-800);
}
