.dropdown-item {
  &,
  &:hover,
  &:focus {
    background: inherit;
  }

  &:hover a {
    color: var(--ab-white);
  }

  a {
    &,
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}
