$padding: 12px;

.form-control.comment-textarea {
  height: 60px;
  box-shadow: none;
  color: var(--ab-text-default);
  border-color: var(--ab-border-grey);
  background-color: var(--ab-bkg-white-light);
}

.comment {
  @include clearfix();
  margin: 0 0 $padding;
  padding: $padding 0 20px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--ab-border-grey);
  color: var(--ab-grey-600);
}

.comment--last {
  border-bottom: none;
}

.comment__body {
  overflow: hidden;
  padding-left: $padding;
}

.comment__author {
  margin-top: 0;
  color: var(--ab-grey-300);
}

.comment__occurred {
  font-size: 11px;
}

.comment__delete {
  padding: 0 4px 1px 4px;
}

.markdown-notice {
  display: inline-block;
}

.markdown-notice__icon {
  margin: 0 5px 0 10px;
  font-size: 16px;
}
