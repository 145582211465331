.ab-user-agent {
  background-repeat: no-repeat;
  height: 16px;
  line-height: 16px;

  &.android {
    background-image: url('../../../assets/images/android.svg');
    padding-left: 20px;
  }

  &.chrome {
    background-image: url('../../../assets/images/chrome.svg');
    padding-left: 20px;
  }

  &.edge {
    background-image: url('../../../assets/images/edge.svg');
    padding-left: 20px;
  }

  &.firefox {
    background-image: url('../../../assets/images/firefox.svg');
    padding-left: 20px;
  }

  &.internet-explorer {
    background-image: url('../../../assets/images/internet-explorer.svg');
    padding-left: 20px;
  }

  &.opera {
    background-image: url('../../../assets/images/opera.svg');
    padding-left: 20px;
  }

  &.safari {
    background-image: url('../../../assets/images/safari.svg');
    padding-left: 20px;
  }
}
