// Red colors are reserved for production environments. Using ab-red-200,
// ab-red-300,  ab-red-400,  ab-red-500, and ab-red-600 from airbrake-colors
// should be avoided for non-production environments to avoid user confusion.

// `stroke` is used for SVG elements i.e. the chart annotations.
// `background-color` is used for block elements i.e. the envLabels.

@mixin abEnvColor($color-name){
  background: var(--#{$prefix}#{$color-name});
  stroke: var(--#{$prefix}#{$color-name});
}

.ab-env-production {
  @include abEnvColor("red-400");
}

.ab-env-development {
  @include abEnvColor("green-500");
}

.ab-env-staging {
  @include abEnvColor("orange-500");
}

.ab-env-0 {
  @include abEnvColor("blue-100");
}

.ab-env-1 {
  @include abEnvColor("blue-200");
}

.ab-env-2 {
  @include abEnvColor("blue-300");
}

.ab-env-3 {
  @include abEnvColor("blue-400");
}

.ab-env-4 {
  @include abEnvColor("blue-500");
}

.ab-env-5 {
  @include abEnvColor("blue-600");
}

.ab-env-6 {
  @include abEnvColor("green-100");
}

.ab-env-7 {
  @include abEnvColor("green-200");
}

.ab-env-8 {
  @include abEnvColor("green-300");
}

.ab-env-9 {
  @include abEnvColor("green-400");
}

.ab-env-10 {
  @include abEnvColor("orange-100");
}

.ab-env-11 {
  @include abEnvColor("orange-200");
}

.ab-env-12 {
  @include abEnvColor("orange-300");
}

.ab-env-13 {
  @include abEnvColor("orange-400");
}

.ab-env-14 {
  @include abEnvColor("red-700");
}

.ab-env-15 {
  @include abEnvColor("grey-600");
}
