.blankslate {
  padding: 15px;
  text-align: center;
}

.blankslate__text {
  margin-bottom: 20px;
  font-size: $ab-font-size-x-default;
  -webkit-font-smoothing: antialiased;
}

.blankslate__image {
  width: 120px;
}
