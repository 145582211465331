.file-line__line,
.file-line__name {
  color: var(--ab-text-dark);
}

.file-line__path,
.file-line__column,
.file-line__colon {
  color: var(--ab-grey-600);
}

.file-line__function-sep {
  color: var(--ab-grey-600);
}

.file-line__function-name {
  color: var(--ab-green-300);
}

.file-line__link {
  &:hover {
    text-decoration: none;
  }
}
