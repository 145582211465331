ab-project-detail {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.project-detail {
  background-color: var(--ab-bkg-grey-lighter);

  @include media-breakpoint-up("md") {
    overflow-y: auto;
    flex-grow: 1;
  }
}

$vertical-spacer: 30px;

.dash-section {
  margin: ($grid-gutter-width * 0.5) $vertical-spacer;
}

.dash-row {
  margin: $vertical-spacer ($grid-gutter-width * 0.5);

  @include media-breakpoint-up("xl") {
    display: flex;
    justify-content: space-between;
  }
}

.dash-row__section {
  position: relative;
  margin: $vertical-spacer 0;

  @include media-breakpoint-up("xl") {
    width: 49%;
    margin: 0;
  }
}

.dash-title {
  margin: 0 0 10px 0;
  font-size: $ab-font-size-x-default;
  -webkit-font-smoothing: antialiased;
  @include clearfix();
  color: var(--ab-text-dark);
}

.dash-title__dropdown.show {
  // The class .show is adding a display: block; which causes a visual
  // issue on this button
  display: inline-block !important;
}

.tooltip--break-word {
  overflow-wrap: break-word;
}
