.project-picker-wrapper {
  min-width: 0; // From W3C specification: https://www.w3.org/TR/css-flexbox-1/#min-size-auto

  .ng-select {
    @include media-breakpoint-up("md") {
      margin-top: 5px;
    }

    .ng-select-container {
      height: 38px;
      background: transparent;
      border: none;
      cursor: pointer;

      &:hover {
        box-shadow: none;

        .btn {
          color: var(--ab-white);
        }
      }
    }

    // Project name shown in the default state
    .btn {
      position: relative;
      display: block;
      width: 100%;
      height: auto;
      border: none;
      padding: 0;
      font-size: 16px;
      line-height: 24px;
      background: none;
      box-shadow: none;
      text-align: left;
      color: var(--ab-grey-800);

      &:focus {
        box-shadow: none;
      }
    }

    .ng-value {
      .text-truncate {
        vertical-align: top;
      }
      .text-muted {
        font-size: 13px;
        color: var(--ab-grey-300);
        font-style: italic;
        vertical-align: top;
      }
    }

    .ng-select-container .ng-value-container {
      padding-left: 0;
    }

    &.ng-select-single .ng-select-container .ng-value-container .ng-input {
      top: 6px;
      width: 100%;
      padding: 0;
      font-size: $ab-font-size-small;
      line-height: 1.8;
      color: var(--ab-white);

      input {
        cursor: pointer;
        color: var(--ab-white);
      }
    }

    .ng-dropdown-panel {
      width: 300px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      background-color: var(--ab-grey-200);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

      .ng-dropdown-panel-items {
        max-height: 510px;
      }

      .ng-option {
        background: transparent;
        color: var(--ab-grey-600);
        cursor: pointer;

        &:last-child {
          border-radius: 0;
        }

        &.ng-option-marked {
          background-color: var(--ab-grey-100);
          color: #fff;
        }

        &.ng-option-selected {
          background-color: var(--ab-grey-100);
          color: var(--ab-grey-600);

          .ng-option-label {
            font-weight: normal;
          }
        }
      }
    }

    .ng-arrow {
      border-top-color: var(--ab-grey-600);
      border-width: 4px 4px 2px;
    }

    .ng-arrow-wrapper:hover .ng-arrow {
      border-top-color: var(--ab-grey-600);
    }

    .ng-dropdown-header {
      padding: 0;
      border: none;
    }

    .new-project {
      display: block;
      margin: 10px;
      padding: 10px 20px;
      text-align: center;
      color: var(--ab-white);
      background-color: var(--ab-grey-300);
      cursor: pointer;

      &:hover {
        text-decoration: none;
        background-color: var(--ab-grey-100);
      }
    }

    &.ng-select-opened {
      .ng-value {
        width: 140px;
      }

      .ng-arrow-wrapper {
        display: none;
      }

      .ng-input {
        border-bottom-width: 1px;
        border-bottom-style: solid;
        border-color: var(--ab-border-grey);
      }
    }
  }
}

.ng-select.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
  border-color: transparent;
  box-shadow: none;
}
