.avatar {
  @include airbrake-hexagon();
  position: relative;
  display: inline-block;
  vertical-align: middle;
  background-color: var(--ab-orange-400);
}

.avatar__image {
  position: absolute;
  left: 0;
  // To stay above .avatar__initial
  z-index: 1;
}

.avatar__initial {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
  font-weight: 400;
  color: var(--ab-white);
}

// Mixins to generate avatars
@mixin avatar($size, $fontSize){
  width: $size;
  height: $size;

  .avatar__image {
    width: $size;
    height: $size;
  }

  .avatar__initial {
    font-size: $fontSize;
    line-height: $size;
  }
}

@mixin avatar-modifier($class, $size, $fontSize){
  .avatar--#{$class} {
    @include avatar($size, $fontSize);
  }
}

@include avatar-modifier(default, 34px, 18px);
@include avatar-modifier(small, 24px, 16px);
@include avatar-modifier(large, 50px, 25px);
