.response-codes-bar-container {
  display: block;
  margin-top: 25px;
  width: 100%;
}

.response-codes-bar-container--absolute {
  position: absolute;
  margin-top: 0;
  top: 10px;
  right: 10px;
  left: 50%;
  width: auto;
}

.response-codes-bar-container--absolute-bottom {
  position: absolute;
  margin-top: 0;
  right: -50%;
  left: 20%;
  bottom: -15px;
  width: auto;
}

.response-codes-bar {
  display: flex;
  position: relative;
}

.response-codes-bar__bar {
  line-height: 8px;
  text-indent: -9999px;
}

.response-codes-tooltip {
  width: 200px;

  .arrow {
    &::before {
      border-top-color: var(--ab-grey-900);
    }
  }

  .tooltip-inner {
    display: flex;
    color: var(--ab-grey-200);
    background-color: var(--ab-grey-900);
  }
}

.response-codes-tooltip__text {
  flex-grow: 1;
  font-size: $font-size-base;
}

.response-codes-tooltip__text-small {
  font-size: $ab-font-size-x-small;
}
