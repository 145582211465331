.deploy-counter {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: $font-size-base;

  @include media-breakpoint-down("md") {
    margin-bottom: 50px;
  }

}

.deploy-footer {
  margin: 10px 0 20px 0;
}

.chart-spinner {
  left: 0;
  right: 0;
  margin: 0;
  padding: 50px 40px;
  height: inherit;
}

.chart-spinner {
  position: absolute;
}

ng-select.deploys-env-selector {
  display: inline-block;
  font-size: 13px;
  vertical-align: middle;

  @include media-breakpoint-up("lg") {
    width: 200px;
  }



  .ng-dropdown-panel {
    background-color: var(--ab-bkg-grey-light);
  }
}

.ng-option.ng-option-disabled {
  text-decoration: line-through;
  color: var(--ab-grey-700);
  cursor: not-allowed;
}
