.ab-sql-highlight {
  .hljs-keyword {
    font-weight: 400;
    color: var(--ab-green-200);
  }

  .hljs-string {
    color: var(--ab-orange-300);
  }
}
