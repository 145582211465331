.activity {
  display: flex;
  margin-bottom: 1.5em;
  align-items: center;
  font-size: $font-size-base;
}

.activity__gravatar {
  margin-right: 8px;
}

.activity__created-at {
  font-size: $ab-font-size-x-small;
  color: var(--ab-grey-600);
}

.activity__icon {
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  margin-right: 8px;
  font-size: 8px;
  text-align: center;
  color: var(--ab-white);
  border-radius: 100%;

  .glyphicon {
    line-height: 2em;
  }
}
