.callout {
  position: absolute;
  top: 60px;
  right: 0;
  width: 350px;
  z-index: 5;
  opacity: 1;
  background-color: var(--ab-white);
  border-radius: 2px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transform-origin: top right;

  $timing-function: cubic-bezier(0.65, 0.05, 0.36, 1);
  transition:
    transform 500ms $timing-function,
    opacity 700ms $timing-function;

  &.ng-hide {
    opacity: 0;
    transform: scale(0.1) translateY(-400%);
  }
}

.callout__close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: var(--ab-white);
}

.callout__header {
  padding: 20px;
  color: var(--ab-white);
  background-color: var(--ab-orange-400);
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
}

.callout__title {
  margin-top: 0;
  font-size: $ab-font-size-default;
  color: var(--ab-white);
}

.callout__body {
  padding: 20px;

  // Remove any margin-bottom from the last element inside .callout__body
  > *:last-child {
    margin-bottom: 0;
  }
}

.callout__dismiss {
  color: var(--ab-grey-700);
  font-size: $ab-font-size-x-small;
  text-decoration: underline;
}

.callout__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4;
  background-color: var(--ab-black);
  opacity: 0.5;
  transition: opacity 200ms linear;
}
