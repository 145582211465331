// Based on the color_mode passed by the ab app to ng app, following highlightjs theme will be loaded accordingly.
html.light {
  @import "~highlight.js/styles/github";
}

html.dark {
  @import "~highlight.js/styles/github-dark";
}

// To account for the "system_default" preference, the following highlightjs theme will be loaded accordingly.
@media (prefers-color-scheme: dark) {
  @import "~highlight.js/styles/github-dark";
}

@media (prefers-color-scheme: light) {
  @import "~highlight.js/styles/github";
}
