$performance-breakdown-left-width: 30%;
$performance-breakdown-right-width: 60%;

.performance-breakdown {
  margin-bottom: 20px;
  padding: 20px;
  background-color: var(--ab-bkg-grey-light);

  @include media-breakpoint-up("md") {
    display: flex;
    flex-wrap: wrap;
  }
}

.performance-breakdown__title {
  margin-top: 0;
  font-size: $h3-font-size;
  color: var(--ab-text-dark);
  -webkit-font-smoothing: antialiased;

  @include media-breakpoint-up("lg") {
    flex-basis: $performance-breakdown-left-width;
  }
}

.performance-breakdown__subtitle {
  font-size: $ab-font-size-default;
  color: var(--ab-grey-600);
}

.performance-breakdown__dropdown {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: $h6-font-size;
}

.performance-breakdown__legends {
  flex-grow: 1;
  flex-basis: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  margin: 0;
  padding: 0;
  list-style: none;

  @include media-breakpoint-up("lg") {
    flex-basis: $performance-breakdown-right-width;
    margin-left: 20px;
  }
}

.performance-breakdown__bkg-grey {
  background-color: var(--ab-grey-800);
}

.performance-breakdown__time {
  flex-basis: $performance-breakdown-left-width;
}

.performance-breakdown__trend {
  display: inline-block;
  font-size: $ab-font-size-x-small;
}

.performance-breakdown__loading {
  @include media-breakpoint-up("md") {
    flex-basis: 100%;
    margin-left: 0;
  }

  @include media-breakpoint-up("lg") {
    justify-content: flex-end;
    flex-basis: $performance-breakdown-right-width;
  }
}
