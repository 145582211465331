.query-counter {
  display: inline-block;
  margin: 0 10px 0 0;
  font-size: $font-size-base;

  @include media-breakpoint-down("md") {
    margin-bottom: 30px;
  }
}

.query-table-cta {
  max-width: 890px;
  padding: 20px;
  margin: 0 auto 30px;
  text-align: center;
  background-color: var(--ab-orange-900);
  border-radius: 10px;

  @include media-breakpoint-up("md") {
    display: flex;
    text-align: left;
  }
}

.query-table-cta__desc {
  margin-right: 30px;
}

.query-table-cta__title {
  margin-top: 0;
}

.query-table-cta__info {
  @include media-breakpoint-up("md") {
    margin-top: 10px;
  }
}
