.code-hunks-toggle {
  margin-bottom: 20px;
}

.backtrace-container {
  overflow-y: hidden;
}

.backtrace {
  margin-bottom: 4px;
  white-space: nowrap;
}

.code-hunk {
  position: relative;
  margin: 0 10px 10px;
  width: 100%;
  color: var(--ab-black);
}

.code-hunk__pre {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.code-hunk__pre--highlight {
  background-color: var(--ab-bkg-orange-light);
}

.code-hunk__line {
  display: table-row;
}

.code-hunk__line--sample {
  opacity: 0.5;
}

.code-hunk__td {
  display: table-cell;
}

.code-hunk__line .code-hunk__td:last-child {
  width: 100%;
}

.code-hunk__upgrade-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  &:active {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
