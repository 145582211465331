.dash-list-item {
  display: flex;
  align-items: baseline;
  padding: 10px;
  margin-bottom: 5px;
  font-size: $font-size-base;
  color: var(--ab-text-dark);
  background-color: var(--ab-bkg-white-light);

  @at-root a#{&}:hover {
    text-decoration: none;
    background-color: var(--ab-bkg-grey-light);
  }

  @at-root a#{&}:focus {
    text-decoration: none;
  }
}

.dash-list-item--bg-grey {
  background-color: var(--ab-bkg-grey-light);
}

.dash-list-item__number {
  flex-shrink: 0;
  flex-basis: 20px;
  text-align: center;
}

.dash-list-item__fixed-width {
  min-width: 48px;
  flex-shrink: 0;
}

.dash-list-item__circle {
  flex-shrink: 0;
  display: inline-block;
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.dash-list-item__text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
