.task--completed {
  margin-left: -1.5em;
  list-style: none;
  text-decoration: line-through;
}

.task__anchor {
  line-height: 2em;

  .task--completed & {
    color: var(--ab-green-400);

    &:hover {
      cursor: default;
      text-decoration: line-through;
    }
  }
}

.task__icon {
  margin-right: 8px;
}
