h1, h2, h3, h4, h5,
.h1, .h2, .h3, .h4, .h5 {
  margin-top: 18px;
  margin-bottom: 9px;
}

input {
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  -webkit-appearance: none;
  border-color: var(--ab-border-grey);
  background-color: var(--ab-bkg-white-light);
}
