.query-list__cell {
  flex-wrap: wrap;
}

.query-list__route-container {
  min-width: 100%;
  line-height: 24px;
}

.query-list__route-list {
  padding: 0;
  list-style-type: none;
}

.query-list__route {
  padding: 0 20px;
}
