.onboarding-progress-wrapper {
  flex-shrink: 0;
}

.onboarding-progress {
  padding: 17px 15px;

  @include media-breakpoint-up("md") {
    float: left;
    width: 125px;
  }

  @include media-breakpoint-up("lg") {
    width: 200px;
    padding: 8px 15px;
  }
}

.onboarding-progress__body {
  color: var(--ab-grey-600);

  &:hover {
    cursor: pointer;
    color: var(--ab-white);
  }
}

.onboarding-progress__title {
  margin: 0;
  font-size: $font-size-base;

  @include media-breakpoint-up("lg") {
    font-size: $ab-font-size-x-small;
  }
}
