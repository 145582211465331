.workbench {
  height: 100%;
}

ab-workbench {
  height: 100%;
}

.dashboard, ab-errors {
  position: relative;
  flex-grow: 1;
  flex-direction: column;
  background-color: var(--ab-bkg-white-pure);

  @include media-breakpoint-up("md") {
    display: flex;
    overflow: auto;
  }
}

ab-errors {
  display: flex;
  width: 100%;
  min-height: 100%;
}

.dashboard > ng-component {
  width: 100%;

  @include media-breakpoint-up("md") {
    display: flex;
    overflow: auto;
    flex-grow: 1;
  }
}

.project-errors-container {
  overflow: auto;

  @include media-breakpoint-up("md") {
    display: flex;
    flex-grow: 1;
  }
}

.groups-wrapper {
  position: relative;

  @include media-breakpoint-up("md") {
    flex-grow: 1;
  }
}

.sidebar {
  padding: 0;
  border-right: 1px solid var(--ab-border-grey);

  @include media-breakpoint-up("md") {
    flex-shrink: 0;
    flex-basis: 384px;
    max-width: 384px;
  }

  &.sidebar--group-is-active {
    @include media-breakpoint-down("md") {
      display: none;
    }
  }

  @include media-breakpoint-up("lg") {
    flex-basis: 460px;
    max-width: 460px;
  }
}

.main {
  padding: 0;

  @include media-breakpoint-up("md") {
    // From W3C specification:
    // By default, flex items won’t shrink below their minimum
    // content size (the length of the longest word or fixed-size
    // element). To change this, set the min-width or min-height
    // property. See https://www.w3.org/TR/css-flexbox-1/#min-size-auto
    //
    // The element .notice-title.truncate has a 'white-space: nowrap' that
    // was causing an overflow bug. That's why we need this min-width here.
    min-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.container-offcanvas {
  @include media-breakpoint-down("md") {
    transition: transform 300ms ease;
  }
}

.container-offcanvas--on {
  @include media-breakpoint-down("md") {
    transform: translateX(80%);
  }
}

.project-nav-info {
  display: flex;
  flex-shrink: 0;
  padding: 1em;
  min-width: 100px;

  @include media-breakpoint-up("md") {
    max-width: 31%;
    padding: 0;
    padding-left: 9px;
  }

  @include media-breakpoint-up("lg") {
    max-width: 27%;
  }

  @include media-breakpoint-up("xl") {
    max-width: 39%;
  }
}

.btn-color-mode {
  color: rgb(205, 205, 215);
  border-color: var(--ab-transparent);
  background-color: var(--ab-transparent);
  box-shadow: none;

  &:hover {
    color: rgb(205, 205, 215);
    background-color: var(--ab-transparent);
  }

  &:focus,
  &:focus:active {
    box-shadow: none;
  }
}
