.interval-list {
  max-width: 200px;
  margin: 0 auto;
  padding-top: 9px;

  @include media-breakpoint-up("md") {
    margin: 0 0 0 auto;
  }
}

.interval-list__item {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 3px;
}

.interval-list__item--hover {
  &:hover {
    cursor: pointer;
    background-color: var(--ab-bkg-grey-light);
  }
}

.interval-list__item--active {
  background-color: var(--ab-bkg-grey-light);
}
